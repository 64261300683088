.ChangePassword .form-group {
  margin-bottom: 0px; }

.ChangePassword .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px; }

.ChangePassword .form-control:focus {
  z-index: 2; }

.ChangePassword #password {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f7f7f7;
  border-color: #f7f7f7; }

.ChangePassword #confirmPassword {
  margin-bottom: 1rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: #f7f7f7;
  border-color: #f7f7f7; }
